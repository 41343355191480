import { useSelector } from "react-redux";
import { selectCurrentTemplate } from "../../store/features/templates.slice";
import LogbookFolders from "../common/logbook-folders";

export default function TemplateFolders() {
  const currentTemplate = useSelector(selectCurrentTemplate);

  return (
    <LogbookFolders
      isPartOfTemplate
      isCompanyUser
      logbookId={currentTemplate.id}
      folders={currentTemplate.folders}
      documents={currentTemplate.documents}
      canEdit
    />
  );
}
