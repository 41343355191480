import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import styled from "styled-components";
import privateFolderIcon from "../../assets/images/folder-private.svg";
import folderIcon from "../../assets/images/folder.svg";
import emptyFolderIcon from "../../assets/images/folder_empty.svg";
import sharedFolderIcon from "../../assets/images/folder_icon_highlighted.svg";
import lockIcon from "../../assets/images/lock_icon.svg";
import AddOrEditFolderModal from "../../components/add-or-edit-folder.modal";
import { isCurrentUserACompany } from "../../store/features/user.slice";
import FolderList from "./folder-list";

/**
 * @typedef {Object} LogbookFoldersProps
 * @property {number} logbookId
 * @property {inndox.Folder[]} folders
 * @property {boolean} [isPartOfTemplate]
 * @property {boolean} canEdit
 * @property {number} [ownerId]
 *
 */

/**
 *
 * @param {LogbookFoldersProps} props
 */
export default function LogbookFolders({
  logbookId,
  folders,
  isPartOfTemplate,
  canEdit,
  ownerId,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showGridView, setShowGridView] = useLocalStorage(
    "folders-grid-view",
    true,
  );
  const [wantsToAddFolder, setWantsToAddFolder] = useState(false);
  const isCompanyUser = useSelector(isCurrentUserACompany);

  const openFolder = (folderId) => {
    navigate(location.pathname.replace("folders", "folder") + "/" + folderId);
  };

  return (
    <DocumentTitle title={`Logbook Folders | inndox`}>
      <Container className="folder">
        <Header>
          <HeaderCol xs={12} md={5} className="flex start">
            <PageTitle>Folders</PageTitle>
          </HeaderCol>

          <HeaderCol xs={12} md={7} className="flex end">
            <Actions>
              <button
                className="button button-alt"
                onClick={() => setShowGridView(!showGridView)}
              >
                Switch to {showGridView ? "List View" : "Grid View"}
              </button>
              <button
                className="button button-primary"
                onClick={() => setWantsToAddFolder(true)}
              >
                New folder
              </button>
            </Actions>
          </HeaderCol>
        </Header>

        {/* Grid View */}
        {showGridView && (
          <Folders>
            {folders.map((folder) => {
              let icon =
                folder.documentStatistics.count > 0
                  ? folderIcon
                  : emptyFolderIcon;
              if (folder.isPrivate) {
                icon = privateFolderIcon;
              } else if (folder.sharePermissions.length > 0) {
                icon = sharedFolderIcon;
              }

              return (
                <FolderItem
                  key={folder.id}
                  title={folder.name}
                  onClick={() => openFolder(folder.id)}
                >
                  <IconContainer>
                    <FolderIcon src={icon} />

                    {!folder.canDelete && <LockIcon src={lockIcon} />}
                  </IconContainer>
                  <FolderItemTitle>{folder.name}</FolderItemTitle>
                </FolderItem>
              );
            })}
          </Folders>
        )}

        {/* List View */}
        {!showGridView && (
          <FolderList
            folders={folders}
            logbookId={logbookId}
            showLabel={false}
            isCurrentUserACompany={isCompanyUser}
            canEdit={canEdit}
            ownerId={ownerId}
          />
        )}

        {/* Add Folder */}
        <AddOrEditFolderModal
          isVisible={wantsToAddFolder}
          onClose={() => setWantsToAddFolder(false)}
          logbookId={logbookId}
          isPartOfTemplate={isPartOfTemplate}
        />
      </Container>
    </DocumentTitle>
  );
}

const Header = styled(Row)``;

const HeaderCol = styled(Col)`
  height: 90px;

  @media only screen and (max-width: 576px) {
    height: 45px;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Folders = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  background-color: #fff;
  padding: 16px 8px;
  border-radius: 8px;

  @media only screen and (max-width: 576px) {
    gap: 16px;
  }
`;

const FolderItem = styled.div`
  width: 140px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const FolderItemTitle = styled.p`
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  height: 48px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PageTitle = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
`;

const FolderIcon = styled.img`
  height: 44px;
`;

const LockIcon = styled.img`
  width: 20px;
  position: absolute;
  right: 0px;
  bottom: 2px;
`;
