import { delay } from "lodash";
import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Hidden, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DocumentsApi } from "../../api/documents";
import { FoldersApi } from "../../api/folders";
import privateFolderIcon from "../../assets/images/folder-private.svg";
import folderIcon from "../../assets/images/folder.svg";
import folderEmptyIcon from "../../assets/images/folder_empty.svg";
import folderIconHighlighted from "../../assets/images/folder_icon_highlighted.svg";
import lockIcon from "../../assets/images/lock_icon.svg";
import lockNewIcon from "../../assets/images/lock_new.svg";
import uploadIcon from "../../assets/images/upload_icon.svg";
import viewIcon from "../../assets/images/view_icon.svg";
import AddOrEditFolderModal from "../../components/add-or-edit-folder.modal";
import { useAuth } from "../../components/authentication";
import FileDroppableArea from "../../components/file-drop/file-droppable-area";
import FileUploadNotification from "../../components/file-drop/file-upload-notification";
import { ALL_ALLOWED_FILE_MIME_TYPES } from "../../components/file-drop/helpers";
import { handleError } from "../../components/helpers";
import InlineEditor from "../../components/inline-editor";
import Modal from "../../components/modal";
import MoreMenu, {
  ADD_LINK_TO_FOLDER_MENU_ITEM,
  DELETE_FOLDER_MENU_ITEM,
  EDIT_FOLDER_MENU_ITEM,
  OPEN_FOLDER_MENU_ITEM,
  SHARE_FOLDER_MENU_ITEM,
  UPLOAD_FILES_TO_FOLDER_MENU_ITEM,
} from "../../components/more-menu";
import ShareLogbookOrContent from "../../components/share-logbook-or-content";
import FolderAndFileUploadModal from "../../components/upload-files-and-folders.modal";
import {
  CompanyRoutes,
  OwnerRoutes,
  SharedRoutes,
  TemplateRoutes,
} from "../../routes";
import {
  removeLogbookFolder,
  updateLogbookDocument,
  updateLogbookFolder,
} from "../../store/features/logbooks.slice";
import {
  removeTemplateFolder,
  updateTemplateFolder,
} from "../../store/features/templates.slice";

const FolderItem = styled.div`
  border-radius: 10px;
  background-color: #fff;
  min-height: 98px;
  padding: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid #fff;

  &.unclickable {
    cursor: default;
  }

  &.highlight {
    border: 1px solid #c6d85b;
    background-color: #fefff5;
  }

  &.drag-highlight {
    border: 1px solid #bfd62f;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 576px) {
    padding: 16px;
  }
`;

const Icon = styled.div`
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d3d7e3;

  @media only screen and (max-width: 576px) {
    padding-right: 16px;
  }
`;

const FolderIcon = styled.div`
  width: 32px;
  height: 29px;
  position: relative;
`;

const FileCount = styled.div`
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #adadb0;
  margin-top: 8px;
`;

const NewFileCount = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
  padding: 2px 6px;
  color: #fff;
  background-color: #ff3e3e;
  font-size: 12px;
  line-height: normal;
  border-radius: 10px;
`;

const Lock = styled.img`
  position: absolute;
  right: -10px;
  bottom: -2px;
`;

const LockNew = styled.img`
  position: absolute;
  right: 10px;
  bottom: 6px;
`;

const Info = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  max-width: calc(100% - 144px);

  @media only screen and (max-width: 576px) {
    max-width: unset;
  }
`;

const Description = styled.p`
  font-size: 13px;
  line-height: normal;
  color: #adadb0;
  padding-left: 8px;
  line-height: 15px;
  max-height: 30px;
  overflow: hidden;
  margin-top: 4px;
`;

const Actions = styled.div`
  width: 160px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 576px) {
    width: 40px;
  }
`;

const NewFileLabel = styled.div`
  padding: 2px 6px;
  color: #fff;
  background-color: #bfd62f;
  font-size: 12px;
  line-height: normal;
  border-radius: 4px;
  margin-right: 8px;
  position: absolute;
  right: 16px;
  top: 16px;
`;

const Permission = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.38;
  color: #d7def0;
  width: 112px;
  flex-shrink: 0;
`;

const PermissionIcon = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

/**
 *
 * @typedef {Object} FolderRowItemProps
 * @property {boolean} isCurrentUserACompany
 * @property {inndox.Folder} folder
 * @property {boolean} canEdit
 * @property {boolean} showPermissionBadge
 * @property {boolean} isReadOnly
 */

/**
 *
 * @param {FolderRowItemProps} props
 * @returns
 */
function FolderRowItem({ isCurrentUserACompany, folder, canEdit, isReadOnly }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [wantsToDeleteFolder, setWantsToDeleteFolder] = useState(false);
  const [wantsToShareFolder, setWantsToShareFolder] = useState(false);
  const [wantsToEditFolder, setWantsToEditFolder] = useState(false);
  const [wantsToUploadFiles, setWantsToUploadFiles] = useState(false);
  const { pathname } = useLocation();
  const isPartOfATemplate = pathname.includes("/templates");

  const saveFolderName = async (newFolderName) => {
    try {
      const response = await FoldersApi.updateFolder(
        folder.propertyId,
        folder.id,
        {
          ...folder,
          name: newFolderName,
        },
      );

      dispatch(updateLogbookFolder(response));
    } catch (e) {
      handleError(e);
    }
  };

  const actionSelectMenuOption = (value) => {
    switch (value) {
      case OPEN_FOLDER_MENU_ITEM.value: {
        // open folder
        const path = isCurrentUserACompany
          ? isPartOfATemplate
            ? CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              TemplateRoutes.template.replace(
                ":templateId",
                folder.propertyId,
              ) +
              "/" +
              TemplateRoutes.templateFolder.replace(":folderId", folder.id)
            : CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              CompanyRoutes.logbookFolder.replace(":folderId", folder.id)
          : OwnerRoutes.basePath.replace("/*", "") +
            "/" +
            OwnerRoutes.logbook.replace(":logbookId", folder.propertyId) +
            "/" +
            OwnerRoutes.logbookFolder.replace(":folderId", folder.id);

        navigate("/" + path);
        break;
      }

      case EDIT_FOLDER_MENU_ITEM.value: {
        setWantsToEditFolder(true);

        break;
      }

      case DELETE_FOLDER_MENU_ITEM.value: {
        // delete folder
        setWantsToDeleteFolder(true);
        break;
      }

      case UPLOAD_FILES_TO_FOLDER_MENU_ITEM.value: {
        // add files to folder
        setWantsToUploadFiles(true);
        break;
      }

      case ADD_LINK_TO_FOLDER_MENU_ITEM.value: {
        // add link to folder
        if (isCurrentUserACompany) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              CompanyRoutes.addLogbookFolderLink.replace(
                ":folderId",
                folder.id,
              ),
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", folder.propertyId) +
              "/" +
              OwnerRoutes.addLogbookFolderLink.replace(":folderId", folder.id),
          );
        }
        break;
      }

      case SHARE_FOLDER_MENU_ITEM.value: {
        // share folder
        setWantsToShareFolder(true);
        break;
      }

      default:
        break;
    }
  };

  const menuOptions = [OPEN_FOLDER_MENU_ITEM];

  const isInReadOnlyMode = isReadOnly;

  if (!isInReadOnlyMode) {
    if (canEdit) {
      menuOptions.push(EDIT_FOLDER_MENU_ITEM);
    }
    if (folder.canUpload) {
      menuOptions.push(UPLOAD_FILES_TO_FOLDER_MENU_ITEM);
      menuOptions.push(ADD_LINK_TO_FOLDER_MENU_ITEM);
    }
    if (!folder.isPrivate && !isPartOfATemplate) {
      menuOptions.push(SHARE_FOLDER_MENU_ITEM);
    }
    if (folder.canDelete) {
      menuOptions.push({
        ...DELETE_FOLDER_MENU_ITEM,
        color: "#FF3E3E",
      });
    }
  }

  const uploadFilesToFolder = (files) => {
    const validFiles = files.filter((file) =>
      ALL_ALLOWED_FILE_MIME_TYPES.includes(file.type),
    );

    if (validFiles.length) {
      toast.dismiss();

      delay(
        () =>
          toast.info(
            <FileUploadNotification
              files={validFiles}
              additionalProps={{
                logbookId: folder.propertyId,
                folderId: folder.id,
              }}
            />,
            {
              autoClose: false,
              position: "bottom-left",
              hideProgressBar: true,
              bodyClassName: "notification",
            },
          ),
        1400,
      );
    }

    if (validFiles.length !== files.length) {
      toast.error("Some invalid files have been ignored.");
    }
  };

  const onExistingFileDropped = async (event) => {
    event.preventDefault();

    const dataString = event.dataTransfer.getData("text/plain");
    const data = JSON.parse(dataString);
    const { type, id } = data;

    if (type === "folder") {
      if (id !== folder.id) {
        // try to move the moved folder to the target folder
        try {
          const updatedTargetFolder = await FoldersApi.moveFolder(
            folder.propertyId,
            id,
            folder.id, // This is the target folder for the folder move
          );

          // For updating local state
          // When we move a folder inside another folder,
          // returned folder is target folder
          // which contains the newly created subfolder
          //
          // To update local state, we need to remove
          // the folder from the logbook and update the target
          // folder
          if (isPartOfATemplate) {
            dispatch(removeTemplateFolder(id));
            dispatch(updateTemplateFolder(updatedTargetFolder));
          } else {
            dispatch(removeLogbookFolder(id));
            dispatch(updateLogbookFolder(updatedTargetFolder));
          }
        } catch (e) {
          handleError(e);
        }
      }
    } else {
      // move this document to the folder
      try {
        const updatedFolder = await DocumentsApi.moveDocumentToFolder(
          folder.propertyId,
          id,
          folder.id,
        );

        dispatch(updateLogbookFolder(updatedFolder));
        dispatch(
          updateLogbookDocument({
            id,
            folderId: folder.id,
          }),
        );
      } catch (e) {
        handleError(e);
      }
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDragStart = (event) => {
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        type: "folder",
        id: folder.id,
      }),
    );
  };

  return (
    <Col
      xs={12}
      draggable
      onDragStart={onDragStart}
      onDrop={onExistingFileDropped}
      onDragOver={onDragOver}
    >
      {folder.canUpload ? (
        <FileDroppableArea multiple onFileDrop={uploadFilesToFolder}>
          {(isDraggingOver) => (
            <FolderItem
              className={`${
                folder.documentStatistics.newCount > 0 ? "highlight" : ""
              } ${isDraggingOver ? "drag-highlight" : ""}`}
              onClick={() => {
                const path = isCurrentUserACompany
                  ? isPartOfATemplate
                    ? CompanyRoutes.basePath.replace("/*", "") +
                      "/" +
                      TemplateRoutes.template.replace(
                        ":templateId",
                        folder.propertyId,
                      ) +
                      "/" +
                      TemplateRoutes.templateFolder.replace(
                        ":folderId",
                        folder.id,
                      )
                    : CompanyRoutes.basePath.replace("/*", "") +
                      "/" +
                      CompanyRoutes.logbook.replace(
                        ":logbookId",
                        folder.propertyId,
                      ) +
                      "/" +
                      CompanyRoutes.logbookFolder.replace(
                        ":folderId",
                        folder.id,
                      )
                  : OwnerRoutes.basePath.replace("/*", "") +
                    "/" +
                    OwnerRoutes.logbook.replace(
                      ":logbookId",
                      folder.propertyId,
                    ) +
                    "/" +
                    OwnerRoutes.logbookFolder.replace(":folderId", folder.id);

                navigate("/" + path);
              }}
            >
              <Icon>
                <FolderIcon>
                  <img
                    src={
                      folder.isPrivate
                        ? privateFolderIcon
                        : folder.documentStatistics.newCount > 0 ||
                            folder.sharePermissions?.length > 0
                          ? folderIconHighlighted
                          : folder.documentStatistics.count > 0
                            ? folderIcon
                            : folderEmptyIcon
                    }
                    alt={
                      folder.isPrivate
                        ? "private folder"
                        : folder.documentStatistics.newCount > 0 ||
                            folder.sharePermissions?.length > 0
                          ? "folder icon with new files"
                          : "folder icon"
                    }
                  />

                  {folder.documentStatistics.newCount > 0 ? (
                    <NewFileCount>
                      {folder.documentStatistics.newCount}
                    </NewFileCount>
                  ) : null}

                  {folder.canDelete ? null : (
                    <Lock src={lockIcon} alt="locked folder" />
                  )}
                </FolderIcon>
                <FileCount>{`${folder.documentStatistics.count} files`}</FileCount>
              </Icon>

              {/* Name and description */}
              <Info>
                <InlineEditor
                  defaultValue={folder.name}
                  bold
                  onConfirm={saveFolderName}
                  editable={canEdit}
                  isDocument={false}
                />

                {folder.description ? (
                  <Description>{folder.description}</Description>
                ) : null}
              </Info>

              {/* Actions */}
              <Actions>
                <MoreMenu
                  options={menuOptions}
                  onSelect={actionSelectMenuOption}
                />
              </Actions>

              {/* Stats */}
              {folder.documentStatistics.newCount > 0 ? (
                <Hidden xs>
                  <NewFileLabel>New file added</NewFileLabel>
                </Hidden>
              ) : null}
            </FolderItem>
          )}
        </FileDroppableArea>
      ) : (
        <FolderItem
          className={folder.documentStatistics.newCount > 0 ? "highlight" : ""}
          onClick={() => {
            const path = isCurrentUserACompany
              ? isPartOfATemplate
                ? CompanyRoutes.basePath.replace("/*", "") +
                  "/" +
                  TemplateRoutes.template.replace(
                    ":templateId",
                    folder.propertyId,
                  ) +
                  "/" +
                  TemplateRoutes.templateFolder.replace(":folderId", folder.id)
                : CompanyRoutes.basePath.replace("/*", "") +
                  "/" +
                  CompanyRoutes.logbook.replace(
                    ":logbookId",
                    folder.propertyId,
                  ) +
                  "/" +
                  CompanyRoutes.logbookFolder.replace(":folderId", folder.id)
              : OwnerRoutes.basePath.replace("/*", "") +
                "/" +
                OwnerRoutes.logbook.replace(":logbookId", folder.propertyId) +
                "/" +
                OwnerRoutes.logbookFolder.replace(":folderId", folder.id);

            navigate("/" + path);
          }}
        >
          <Icon>
            <FolderIcon>
              <img
                src={
                  folder.isPrivate
                    ? privateFolderIcon
                    : folder.documentStatistics.newCount > 0 ||
                        folder.sharePermissions?.length > 0
                      ? folderIconHighlighted
                      : folder.documentStatistics.count > 0
                        ? folderIcon
                        : folderEmptyIcon
                }
                alt={
                  folder.isPrivate
                    ? "private folder"
                    : folder.documentStatistics.newCount > 0 ||
                        folder.sharePermissions?.length > 0
                      ? "folder icon with new files"
                      : "folder icon"
                }
              />

              {folder.documentStatistics.newCount > 0 ? (
                <NewFileCount>
                  {folder.documentStatistics.newCount}
                </NewFileCount>
              ) : null}

              {folder.canDelete ? null : (
                <Lock src={lockIcon} alt="locked folder" />
              )}
            </FolderIcon>
            <FileCount>{`${folder.documentStatistics.count} files`}</FileCount>
          </Icon>

          {/* Name and description */}
          <Info>
            <InlineEditor
              defaultValue={folder.name}
              bold
              onConfirm={saveFolderName}
              editable={canEdit}
              isDocument={false}
            />

            {folder.description ? (
              <Description>{folder.description}</Description>
            ) : null}
          </Info>

          {/* Actions */}
          <Actions>
            <MoreMenu options={menuOptions} onSelect={actionSelectMenuOption} />
          </Actions>

          {/* Stats */}
          {folder.documentStatistics.newCount > 0 ? (
            <Hidden xs>
              <NewFileLabel>New file added</NewFileLabel>
            </Hidden>
          ) : null}
        </FolderItem>
      )}

      <Modal
        isOpen={wantsToDeleteFolder}
        title="Delete folder?"
        onClose={() => setWantsToDeleteFolder(false)}
      >
        <p className="margin-top-2">
          If you delete the folder, any files within the folder will be
          archived. Are you sure you want to proceed?
        </p>

        <div className="flex end margin-top-3">
          <button
            className="button button-alt margin-right-2"
            onClick={() => setWantsToDeleteFolder(false)}
          >
            Cancel
          </button>
          <Form
            onSubmit={async () => {
              try {
                await FoldersApi.removeFolder(folder.propertyId, folder.id);

                setWantsToDeleteFolder(false);

                if (isPartOfATemplate) {
                  dispatch(removeTemplateFolder(folder.id));
                } else {
                  dispatch(removeLogbookFolder(folder.id));
                }
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <button
                className="button button-archive"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#FF3E3E" />
                ) : (
                  "Delete"
                )}
              </button>
            )}
          />
        </div>
      </Modal>

      <FolderAndFileUploadModal
        isVisible={wantsToUploadFiles}
        onClose={() => setWantsToUploadFiles(false)}
        logbookId={folder.propertyId}
        folderId={folder.id}
        isAddingToATemplate={isPartOfATemplate}
      />

      <ShareLogbookOrContent
        isOpen={wantsToShareFolder}
        onClose={() => setWantsToShareFolder(false)}
        folder={folder}
      />

      <AddOrEditFolderModal
        isVisible={wantsToEditFolder}
        onClose={() => setWantsToEditFolder(false)}
        logbookId={folder.propertyId}
        folder={folder}
        isPartOfTemplate={isPartOfATemplate}
      />
    </Col>
  );
}

/**
 *
 * @typedef {Object} SharedFolderRowItemProps
 * @property {number} logbookId
 * @property {inndox.SharedFolder} folder
 */

/**
 *
 * @param {SharedFolderRowItemProps} props
 * @returns
 */
function SharedFolderRowItem({ folder, logbookId }) {
  const navigate = useNavigate();

  const actionSelectMenuOption = (value) => {
    switch (value) {
      case OPEN_FOLDER_MENU_ITEM.value: {
        // open folder
        const path =
          "/" +
          SharedRoutes.basePath.replace("/*", "") +
          "/" +
          SharedRoutes.folder
            .replace(":logbookId", logbookId)
            .replace(":folderId", folder.id);

        navigate(path);
        break;
      }

      case UPLOAD_FILES_TO_FOLDER_MENU_ITEM.value: {
        // add files to folder
        const path =
          "/" +
          SharedRoutes.basePath.replace("/*", "") +
          "/" +
          SharedRoutes.addDocuments
            .replace(":logbookId", logbookId)
            .replace(":folderId", folder.id);
        navigate(path);

        break;
      }

      case ADD_LINK_TO_FOLDER_MENU_ITEM.value: {
        // add link to folder
        const path =
          "/" +
          SharedRoutes.basePath.replace("/*", "") +
          "/" +
          SharedRoutes.addLink
            .replace(":logbookId", logbookId)
            .replace(":folderId", folder.id);
        navigate(path);

        break;
      }

      default:
        break;
    }
  };

  const menuOptions = [OPEN_FOLDER_MENU_ITEM];
  if (folder.sharePermissions.includes("Create")) {
    menuOptions.push(UPLOAD_FILES_TO_FOLDER_MENU_ITEM);
    menuOptions.push(ADD_LINK_TO_FOLDER_MENU_ITEM);
  }

  const canUploadFile = folder.sharePermissions.indexOf("Create") > -1;

  return (
    <Col xs={12}>
      <FolderItem
        className={folder.documentStatistics.newCount > 0 ? "highlight" : ""}
        onClick={() => {
          const path =
            "/" +
            SharedRoutes.basePath.replace("/*", "") +
            "/" +
            SharedRoutes.folder
              .replace(":logbookId", logbookId)
              .replace(":folderId", folder.id);

          navigate(path);
        }}
      >
        <Icon>
          <FolderIcon>
            <img
              src={
                folder.isPrivate
                  ? privateFolderIcon
                  : folder.documentStatistics.newCount > 0 ||
                      folder.sharePermissions?.split(", ").length > 1
                    ? folderIconHighlighted
                    : folderIcon
              }
              alt={
                folder.isPrivate
                  ? "private folder"
                  : folder.documentStatistics.newCount > 0 ||
                      folder.sharePermissions?.split(", ").length > 1
                    ? "folder icon with new files"
                    : "folder icon"
              }
            />

            {folder.documentStatistics.newCount > 0 ? (
              <NewFileCount>{folder.documentStatistics.newCount}</NewFileCount>
            ) : null}

            {folder.sharePermissions?.split(", ").length > 1 ? null : (
              <LockNew src={lockNewIcon} alt="locked folder" />
            )}
          </FolderIcon>
        </Icon>

        {/* Name and description */}
        <Info>
          <InlineEditor
            defaultValue={folder.name}
            bold
            editable={false}
            isDocument={false}
          />

          {folder.description ? (
            <Description>{folder.description}</Description>
          ) : null}
        </Info>

        {/* Permissions */}
        <Actions>
          <Hidden xs sm>
            {canUploadFile ? (
              <Permission>
                <PermissionIcon>
                  <img src={uploadIcon} alt="Upload icon" />
                </PermissionIcon>
                Upload
              </Permission>
            ) : (
              <Permission>
                <PermissionIcon>
                  <img src={viewIcon} alt="View only icon" />
                </PermissionIcon>
                View only
              </Permission>
            )}
          </Hidden>

          <MoreMenu options={menuOptions} onSelect={actionSelectMenuOption} />
        </Actions>

        {/* Stats */}
        {folder.documentStatistics.newCount > 0 ? (
          <Hidden xs>
            <NewFileLabel>New file added</NewFileLabel>
          </Hidden>
        ) : null}
      </FolderItem>
    </Col>
  );
}

/**
 *
 * @typedef {Object} FolderListProps
 * @property {inndox.Folder[] | inndox.SharedFolder[]} folders
 * @property {boolean} canEdit
 * @property {boolean} isCurrentUserACompany
 * @property {boolean} showLabel
 * @property {number} logbookId
 * @property {number} [ownerId]
 * @property {boolean} [showLinkToAllFolders]
 */

/**
 *
 * @param {FolderListProps} props
 */
export default function FolderList(props) {
  const { pathname } = useLocation();
  const isViewingSharedFolder = pathname.startsWith(
    "/" + SharedRoutes.basePath.replace("/*", ""),
  );
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook =
    props.ownerId === null || props.ownerId === undefined
      ? false // its a company logbook
      : session.userId !== props.ownerId;
  const navigate = useNavigate();
  const [wantsToAddFolder, setWantsToAddFolder] = useState(false);

  return (
    <Row style={{ marginBottom: 16 }}>
      {props.showLabel && props.folders?.length > 0 ? (
        <Col xs={12}>
          <label>Folders</label>
        </Col>
      ) : (
        <noscript />
      )}

      {props.folders.map((folder, index) => {
        return isViewingSharedFolder ? (
          <SharedFolderRowItem
            key={`folder-${index}`}
            logbookId={props.logbookId}
            folder={folder}
          />
        ) : (
          <FolderRowItem
            key={`folder-${index}`}
            folder={folder}
            canEdit={props.canEdit}
            isCurrentUserACompany={props.isCurrentUserACompany}
            showPermissionBadge={props.showPermissionBadge}
            isReadOnly={isCurrentUserAReadOnlyMemberForLogbook}
          />
        );
      })}

      {props.showLinkToAllFolders && (
        <Col xs={12} className="flex end margin-top-1" style={{ gap: 8 }}>
          <button
            className="button button-secondary"
            onClick={() => setWantsToAddFolder(true)}
          >
            Add new
          </button>
          <button
            className="button button-alt"
            onClick={() => navigate(`${pathname}/folders`)}
          >
            View all
          </button>
        </Col>
      )}

      {/* Add Folder */}
      <AddOrEditFolderModal
        isVisible={wantsToAddFolder}
        onClose={() => setWantsToAddFolder(false)}
        logbookId={props.logbookId}
        isPartOfTemplate={pathname.startsWith("/company/templates")}
      />
    </Row>
  );
}
