import moment from "moment";
import { useState } from "react";
import { Col, Row } from "react-grid-system";
import { toast } from "react-toastify";
import warrantyImage from "../assets/images/warranty.svg";
import AddOrEditWarrantyModal from "../pages/common/add-or-edit-warranty-modal";
import { useAuth } from "./authentication";
import SetupBlockWithImage from "./setup-block-with-image";
import Toast from "./toast";
import WarrantyRemindersList from "./warranty-reminder-list";

/**
 * @typedef {Object} WarrantyRemindersWidgetProps
 * @property {inndox.LogbookDocument[]} documents
 * @property {boolean} isCompanyUser
 * @property {number} ownerId
 */

/**
 *
 * @param {WarrantyRemindersWidgetProps} props
 * @returns
 */
export default function WarrantyRemindersWidget({
  documents,
  isCompanyUser,
  ownerId,
}) {
  const [wantsToAddOrEditWarranty, setWantsToAddOrEditWarranty] =
    useState(false);
  const now = moment();
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook = session.userId !== ownerId;
  const documentsWithWarranty = documents
    .filter(
      (document) =>
        document.warrantyExpiresOn &&
        moment(document.warrantyExpiresOn).unix() > now.unix(),
    )
    .sort(
      (a, b) =>
        moment(a.warrantyExpiresOn).unix() - moment().unix(b.warrantyExpiresOn),
    );

  if (!documentsWithWarranty?.length) {
    if (!isCompanyUser && isCurrentUserAReadOnlyMemberForLogbook) {
      return <noscript />;
    }

    return (
      <>
        <Row className="margin-top-3">
          <Col xs={12} className="margin-top-2">
            <label>Warranty Reminders</label>
          </Col>
        </Row>

        <SetupBlockWithImage
          title="Setup Warranty reminders."
          description="You have no warranty reminders listed in your account just yet."
          image={warrantyImage}
        >
          <div className="margin-top-2">
            <button
              className="button button-secondary"
              onClick={() => {
                // templates do not have warranties

                if (documents?.length) {
                  setWantsToAddOrEditWarranty(true);
                } else {
                  toast.error(
                    <Toast title="Please upload a document before trying to set a warranty." />,
                  );
                }
              }}
            >
              Setup warranty reminder
            </button>

            <AddOrEditWarrantyModal
              isOpen={wantsToAddOrEditWarranty}
              onClose={() => setWantsToAddOrEditWarranty(false)}
            />
          </div>
        </SetupBlockWithImage>
      </>
    );
  }

  return (
    <>
      <Row className="margin-top-3">
        <Col xs={12} className="margin-top-2">
          <label>Warranty Reminders</label>
        </Col>
      </Row>

      <WarrantyRemindersList
        documents={documentsWithWarranty.slice(0, 5)}
        showActions
        canEdit={isCompanyUser || !isCurrentUserAReadOnlyMemberForLogbook}
      />
    </>
  );
}
