import moment from "moment";
import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Hidden, Row, Visible } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { MaintenanceItemsApi } from "../api/maintenance-items";
import lockIcon from "../assets/images/lock_icon.svg";
import snoozeGrayscaleIcon from "../assets/images/snooze-grayscale.svg";
import snoozeIcon from "../assets/images/snooze.svg";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../routes";
import {
  removeLogbookMaintenanceItem,
  updateLogbookMaintenanceItem,
} from "../store/features/logbooks.slice";
import { removeTemplateMaintenanceItem } from "../store/features/templates.slice";
import { isCurrentUserACompany } from "../store/features/user.slice";
import AddToCalendar from "./add-to-calendar";
import { useAuth } from "./authentication";
import Checkbox from "./form/checkbox";
import DatePicker from "./form/date-picker";
import { handleError } from "./helpers";
import Modal from "./modal";
import MoreMenu, {
  DELETE_MAINTENANCE_MENU_ITEM,
  EDIT_MAINTENANCE_MENU_ITEM,
  SNOOZE_MAINTENANCE_MENU_ITEM,
  UNSNOOZE_MAINTENANCE_MENU_ITEM,
  VIEW_MAINTENANCE_MENU_ITEM,
} from "./more-menu";
import SetupBlock from "./setup-block";
import Toast from "./toast";

const Title = styled.div`
  margin: 0;
  white-space: normal;
  color: #2d3540;
  display: flex;

  &.uneditable {
    opacity: 0.85;
  }

  img {
    margin-right: 8px;
  }
`;

const CollapsibleText = styled.span`
  font-size: 16px;
  line-height: 24px;
  max-height: 48px;
  position: relative;
  overflow: hidden;
  display: block;

  &:before {
    content: "...";
    position: absolute;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    top: 24px;
    right: 78px;
    padding-right: 4px;
    padding-left: 8px;
    background-color: #fff;
  }
  &:after {
    content: "Show more";
    position: absolute;
    font-size: 16px;
    line-height: 24px;
    top: 24px;
    right: 0;
    background-color: #fff;
    color: #68c9d6;
    font-weight: 500;
    cursor: pointer;
  }

  &.expanded {
    max-height: initial;

    &:before {
      display: none;
    }

    &:after {
      content: "Show less";
      top: auto;
      bottom: 0;
    }
  }
`;

const Label = styled.span`
  display: inline-block;
  font-size: 12px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: bold;
`;

const DueTodayLabel = styled(Label)`
  background-color: #d44215;
  color: #fff;
`;

const UpcomingLabel = styled(Label)`
  background-color: #fecb48;
  color: #4a4a4a;
`;

const SnoozeLabel = styled(Label)`
  background-color: #68c9d6;
  color: #fff;
`;

const FormContent = styled.div`
  max-width: 200px;
`;

function CollapsibleNotes(props) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <CollapsibleText
      className={!isCollapsed ? "expanded" : ""}
      onClick={() => setIsCollapsed((_) => !_)}
    >
      {props.text || ""}
    </CollapsibleText>
  );
}

/**
 * @typedef {Object} MaintenanceItemRowItemProps
 * @property {inndox.LogbookMaintenanceItem} maintenanceItem
 * @property {boolean} isPartOfATemplate
 * @property {inndox.Contact[]} contacts
 * @property {boolean} isCompanyUser
 */

/**
 *
 * @param {MaintenanceItemRowItemProps} props
 */
function MaintenanceItemRowItem({
  maintenanceItem,
  isPartOfATemplate,
  contacts,
  isCompanyUser,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wantsToDelete, setWantsToDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [wantsToSnooze, setWantsToSnooze] = useState(false);
  const isLocked = !maintenanceItem.canEdit;
  const contact = contacts.find(
    (contact) => contact.id === maintenanceItem.scheduledFor,
  );
  const contactToDisplay = maintenanceItem.scheduledFor
    ? contact
      ? contact.firstName + " " + contact.lastName
      : "Contact Removed"
    : "-";

  const editMaintenanceItem = () => {
    if (isCompanyUser) {
      navigate(
        "/" +
          CompanyRoutes.basePath.replace("/*", "") +
          "/" +
          CompanyRoutes.logbook.replace(
            ":logbookId",
            maintenanceItem.propertyId,
          ) +
          "/" +
          CompanyRoutes.editLogbookMaintenanceItem.replace(
            ":maintenanceId",
            maintenanceItem.id,
          ),
      );
    } else {
      navigate(
        "/" +
          OwnerRoutes.basePath.replace("/*", "") +
          "/" +
          OwnerRoutes.logbook.replace(
            ":logbookId",
            maintenanceItem.propertyId,
          ) +
          "/" +
          OwnerRoutes.editLogbookMaintenanceItem.replace(
            ":maintenanceId",
            maintenanceItem.id,
          ),
      );
    }
  };

  const getDueDate = () => {
    if (!isCompanyUser && maintenanceItem.nextScheduledEvent === null) {
      return <noscript />;
    }

    if (
      maintenanceItem.repeatsFor === 1 &&
      maintenanceItem.nextScheduledEvent === null
    ) {
      return (
        <>
          One off
          {!isPartOfATemplate ? (
            <span
              className="link-style-elem"
              style={{ display: "block" }}
              onClick={editMaintenanceItem}
            >
              <span className="link-style-elem">Set a date</span>
            </span>
          ) : (
            ""
          )}
        </>
      );
    }

    if (maintenanceItem.nextScheduledEvent) {
      const now = moment();
      if (moment(maintenanceItem.nextScheduledEvent).isBefore(now, "day")) {
        return (
          <span className="maintenance-item-due-date-label archived">
            Archived
          </span>
        );
      }
    }

    return maintenanceItem.nextScheduledEvent
      ? moment(maintenanceItem.nextScheduledEvent).format("DD/MM/YYYY")
      : `Handover + ${maintenanceItem.frequencyInMonths} 
        ${maintenanceItem.frequencyInMonths > 1 ? "Months" : "Month"}`;
  };

  const getLabel = () => {
    if (maintenanceItem?.nextScheduledEvent) {
      const now = moment();
      const dueDate = moment(maintenanceItem.nextScheduledEvent);

      //if already finished, nothing to display
      if (dueDate.isBefore(now, "day")) {
        return <noscript />;
      }

      if (dueDate.isSame(now, "day")) {
        // return due today label
        return <DueTodayLabel>Due Today</DueTodayLabel>;
      }

      const upcomingDate = moment();
      upcomingDate.add(14, "d");
      if (dueDate.isBefore(upcomingDate, "day")) {
        // return upcoming label
        return <UpcomingLabel>Upcoming</UpcomingLabel>;
      }
    } else {
      if (!isCompanyUser) {
        return <SnoozeLabel>Snoozed</SnoozeLabel>;
      }
    }

    return <noscript />;
  };

  const deleteMaintenance = async () => {
    setIsDeleting(true);

    try {
      await MaintenanceItemsApi.removeItem(
        maintenanceItem.propertyId,
        maintenanceItem.id,
      );

      if (isPartOfATemplate) {
        dispatch(removeTemplateMaintenanceItem(maintenanceItem.id));
      } else {
        dispatch(removeLogbookMaintenanceItem(maintenanceItem.id));
      }
    } catch (e) {
      handleError(e);
    } finally {
      setWantsToDelete(false);
      setIsDeleting(false);
    }
  };

  const unsnoozeMaintenanceItem = async () => {
    try {
      await MaintenanceItemsApi.unsnoozeItem(
        maintenanceItem.propertyId,
        maintenanceItem.id,
      );

      const updatedItem = await MaintenanceItemsApi.fetchItem(
        maintenanceItem.propertyId,
        maintenanceItem.id,
      );
      dispatch(updateLogbookMaintenanceItem(updatedItem));

      toast.success(
        <Toast title="Success! Maintenance item has been unsnoozed." />,
      );
    } catch (e) {
      handleError(e);
    }
  };

  const actionSelectedMenuOption = (value) => {
    switch (value) {
      case VIEW_MAINTENANCE_MENU_ITEM.value: {
        // view item
        if (isPartOfATemplate) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              TemplateRoutes.template.replace(
                ":templateId",
                maintenanceItem.propertyId,
              ) +
              "/" +
              TemplateRoutes.viewTemplateMaintenanceItem.replace(
                ":maintenanceId",
                maintenanceItem.id,
              ),
          );
        } else if (isCompanyUser) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(
                ":logbookId",
                maintenanceItem.propertyId,
              ) +
              "/" +
              CompanyRoutes.viewLogbookMaintenanceItem.replace(
                ":maintenanceId",
                maintenanceItem.id,
              ),
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(
                ":logbookId",
                maintenanceItem.propertyId,
              ) +
              "/" +
              OwnerRoutes.viewLogbookMaintenanceItem.replace(
                ":maintenanceId",
                maintenanceItem.id,
              ),
          );
        }
        break;
      }

      case EDIT_MAINTENANCE_MENU_ITEM.value: {
        // edit item
        editMaintenanceItem();
        break;
      }

      case DELETE_MAINTENANCE_MENU_ITEM.value: {
        // delete item
        setWantsToDelete(true);
        break;
      }

      case SNOOZE_MAINTENANCE_MENU_ITEM.value: {
        setWantsToSnooze(true);
        break;
      }

      case UNSNOOZE_MAINTENANCE_MENU_ITEM.value: {
        unsnoozeMaintenanceItem();
        break;
      }

      default:
        break;
    }
  };

  const menuOptions = [];
  menuOptions.push(VIEW_MAINTENANCE_MENU_ITEM);
  if (!isLocked) {
    menuOptions.push(EDIT_MAINTENANCE_MENU_ITEM);

    if (!isPartOfATemplate && !isCompanyUser) {
      if (!maintenanceItem.nextScheduledEvent) {
        // already snoozed
        menuOptions.push(UNSNOOZE_MAINTENANCE_MENU_ITEM);
      } else {
        menuOptions.push(SNOOZE_MAINTENANCE_MENU_ITEM);
      }
    }

    menuOptions.push(DELETE_MAINTENANCE_MENU_ITEM);
  }

  const addToCalendarWidget = maintenanceItem.nextScheduledEvent ? (
    <AddToCalendar
      title={maintenanceItem.title}
      description={maintenanceItem.notes}
      date={maintenanceItem.nextScheduledEvent}
      // TODO: Add location if possible
      location=""
    />
  ) : (
    <noscript />
  );
  return (
    <Row className="documents">
      <Hidden xs>
        <Col sm={5} md={2} className="col-body">
          <Title
            className={isLocked ? "uneditable" : ""}
            style={{ display: "block" }}
          >
            <span>{getDueDate()}</span>

            {/* label */}
            {getLabel()}
          </Title>
        </Col>
      </Hidden>

      <Col xs={9} sm={5} md={3} className="col-body">
        <Title className={isLocked ? "uneditable" : ""}>
          {/* lock icon */}
          {isLocked ? <img src={lockIcon} alt="lock icon" /> : <noscript />}

          {/* snooze icon */}
          {maintenanceItem.nextScheduledEvent ? (
            <noscript />
          ) : (
            <img src={snoozeGrayscaleIcon} alt="snoozed" />
          )}

          {/* title */}
          {maintenanceItem.title}

          {/* Add to Calendar */}
          <Visible xs>{addToCalendarWidget}</Visible>
        </Title>
      </Col>

      <Hidden xs sm>
        <Col md={3} className="col-body">
          <Title className={isLocked ? "uneditable" : ""}>
            {contactToDisplay}
          </Title>
        </Col>
      </Hidden>

      <Hidden xs sm>
        <Col md={3} className="col-body">
          <Title className={isLocked ? "uneditable" : ""}>
            <CollapsibleNotes text={maintenanceItem.notes} />
          </Title>

          {addToCalendarWidget}
        </Col>
      </Hidden>

      <Col xs={3} sm={2} md={1} className="col-body flex end">
        <MoreMenu options={menuOptions} onSelect={actionSelectedMenuOption} />
      </Col>

      {/* Delete Modal */}
      <Modal
        isOpen={wantsToDelete}
        onClose={() => setWantsToDelete(false)}
        title="Delete this maintenance item?"
      >
        <p>
          If you delete this maintenance item, it will be gone forever. Are you
          sure you want to proceed?
        </p>

        <div className="flex end margin-top-3">
          <button
            className="button button-alt margin-right-2"
            onClick={() => setWantsToDelete(false)}
          >
            Cancel
          </button>

          <button
            className="button button-archive margin-right-2"
            onClick={deleteMaintenance}
          >
            {isDeleting ? (
              <ClipLoader loading size={16} color="#fff" />
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </Modal>

      {/* Snooze Modal */}
      <Modal
        isOpen={wantsToSnooze}
        onClose={() => setWantsToSnooze(false)}
        title={
          <>
            <img src={snoozeIcon} alt="snooze" />
            <p>Snooze maintenance item</p>
          </>
        }
      >
        <p>
          Snooze <strong>{maintenanceItem.title}</strong>
        </p>

        <Form
          onSubmit={async (values) => {
            try {
              let data = {};
              // gather data
              if (values.snoozeForever) {
                // snooze item
                await MaintenanceItemsApi.snoozeItem(
                  maintenanceItem.propertyId,
                  maintenanceItem.id,
                );

                // fetch updated item
                const updatedItem = await MaintenanceItemsApi.fetchItem(
                  maintenanceItem.propertyId,
                  maintenanceItem.id,
                );
                dispatch(updateLogbookMaintenanceItem(updatedItem));

                // notify user
                toast.success(
                  <Toast title="Success! The maintenance item has been snoozed." />,
                );

                setWantsToSnooze(false);
              } else {
                if (values.nextScheduledEvent) {
                  // date specified
                  data.nextScheduledEvent = values.nextScheduledEvent;

                  // snooze item
                  await MaintenanceItemsApi.snoozeItem(
                    maintenanceItem.propertyId,
                    maintenanceItem.id,
                    data,
                  );

                  // fetch updated item
                  const updatedItem = await MaintenanceItemsApi.fetchItem(
                    maintenanceItem.propertyId,
                    maintenanceItem.id,
                  );
                  dispatch(updateLogbookMaintenanceItem(updatedItem));

                  // notify user
                  toast.success(
                    <Toast title="Success! The maintenance item has been snoozed." />,
                  );

                  setWantsToSnooze(false);
                } else {
                  // let user know date is required
                  toast.error(
                    <Toast title="You must either select a snooze end date or choose to snooze this item forever." />,
                  );
                }
              }
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => {
            const minDate = new Date(maintenanceItem.nextScheduledEvent);
            minDate.setDate(minDate.getDate() + 1);

            return (
              <FormContent>
                <DatePicker
                  label="Snooze expiry"
                  name="nextScheduledEvent"
                  className="margin-top-2"
                  minDate={minDate}
                />

                <Checkbox
                  label="No end date"
                  name="snoozeForever"
                  className="margin-top-1"
                />

                <button
                  className="button button-primary button-large margin-top-4"
                  style={{ width: 150 }}
                  onClick={props.submitting ? null : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Snooze"
                  )}
                </button>

                <style
                  dangerouslySetInnerHTML={{
                    __html: `.rw-widget.rw-calendar { width: 300px; }`,
                  }}
                />
              </FormContent>
            );
          }}
        />
      </Modal>
    </Row>
  );
}

/**
 * @typedef {Object} MaintenanceItemListProps
 * @property {inndox.LogbookMaintenanceItem[]} items
 * @property {inndox.Contact[]} contacts
 * @property {boolean} isPartOfATemplate
 * @property {boolean} logbookIsTransferred
 * @property {number} [ownerId]
 */

/**
 *
 * @param {MaintenanceItemListProps} props
 * @returns
 */
export default function MaintenanceItemList({
  contacts,
  isPartOfATemplate,
  items,
  logbookIsTransferred,
  showActions,
  ownerId,
}) {
  const navigate = useNavigate();
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook = session.userId !== ownerId;

  if (!items?.length) {
    return (
      <SetupBlock
        description={`There are no maintenance items in this ${
          isPartOfATemplate ? "template" : "logbook"
        } yet`}
      />
    );
  }

  const shouldShowAddAction = isCompanyUser
    ? logbookIsTransferred
      ? false
      : true
    : !isCurrentUserAReadOnlyMemberForLogbook;

  return (
    <div className="document-list">
      <Row>
        <Hidden xs>
          <Col sm={5} md={2} className="col-head">
            Due Date
          </Col>
        </Hidden>

        <Col xs={9} sm={5} md={3} className="col-head">
          Title
        </Col>

        <Hidden xs sm>
          <Col md={3} className="col-head">
            Contact
          </Col>
        </Hidden>

        <Hidden xs sm>
          <Col md={3} className="col-head">
            Notes
          </Col>
        </Hidden>

        <Col xs={3} sm={2} md={1} className="col-head"></Col>
      </Row>

      {items.map((item, i) => (
        <MaintenanceItemRowItem
          maintenanceItem={item}
          key={`maintenance-item-${i}`}
          contacts={contacts}
          isCompanyUser={isCompanyUser}
          isPartOfATemplate={isPartOfATemplate}
        />
      ))}

      {showActions ? (
        <Row
          style={{
            borderTop: "1px solid #dde0e3",
            paddingTop: 16,
            paddingBottom: 8,
          }}
        >
          <div className="flex end" style={{ width: "100%" }}>
            {shouldShowAddAction ? (
              <button
                id="add-maintenance-item"
                className="button button-secondary"
                onClick={() => {
                  if (isCompanyUser) {
                    navigate(CompanyRoutes.addLogbookMaintenanceItem);
                  } else {
                    navigate(OwnerRoutes.addLogbookMaintenanceItem);
                  }
                }}
              >
                Add new
              </button>
            ) : (
              <noscript />
            )}

            <button
              className="button button-alt margin-left-1"
              onClick={() => {
                if (isCompanyUser) {
                  navigate(CompanyRoutes.logbookMaintenanceItems);
                } else {
                  navigate(OwnerRoutes.logbookMaintenanceItems);
                }
              }}
            >
              View all
            </button>
          </div>
        </Row>
      ) : (
        <noscript />
      )}
    </div>
  );
}
